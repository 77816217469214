import React from "react";

// components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import SecondaryLanding from "../../../layouts/secondary-landing";
import FaqAccordion from "../../../components/faq/faq-accordion";
import MktoForm from "../../../components/mkto-form/mkto-form";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";

// Helpers
import getSortedFaqDataByCategory from "../../../helpers/getSortedFaqDataByCategory";

const OnlineBankingFAQ = () => {
  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Online & Mobile Banking"]);

  const title = "Online & Mobile Banking FAQs",
    description =
      "Have a question about online or mobile banking at WaFd Bank? Find out about our online banking and mobile app enhancements to help you manage your money easier.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/online-banking/faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-online-banking-02-250.jpg"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/online-banking",
      title: "Mobile & Online Banking"
    },
    {
      id: 3,
      active: true,
      title: "FAQs"
    }
  ];

  const faqAccordionData = {
    id: "online-and-mobile-banking-faq-sections",
    title: "FAQs",
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  const mobileAppInstructionalVideo = {
    id: "wafd-mobile-app-instructional-video",
    vimeoId: "1024072619",
    class: "m-auto iframe w-100 border-radius-12",
    videoTitle: "WaFd Bank - New Mobile App Instructional Video"
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container pb-0">
        <h1>Mobile and Online Banking Frequently Asked Questions</h1>
        <h4>
          Welcome to our new WaFd Bank mobile app. We're excited to show you a new look and streamlined layout to make
          managing your money easier than ever. While there are changes to some tools (more on that below), we've kept
          many of your favorites, including Zelle<sup>&reg;</sup>, debit card controls, and mobile deposit.
        </h4>
        <div className="row">
          <div className="col-lg-6">
            <VimeoVideoFrame {...mobileAppInstructionalVideo} />
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default OnlineBankingFAQ;
