import React from "react";
import { useInView } from "react-intersection-observer";

import styles from "./vimeo-video-frame.module.scss";

const VimeoVideoFrame = (props) => {
  let vimeoIframeRef, isVimeoIframeInView;
  if (typeof window !== "undefined" && typeof window.IntersectionObserver !== "undefined") {
    [vimeoIframeRef, isVimeoIframeInView] = useInView({
      triggerOnce: true
    });
  } else if (typeof window !== "undefined" && typeof window.IntersectionObserver === "undefined") {
    isVimeoIframeInView = true;
  }

  return (
    <>
      <iframe
        id={props.id}
        ref={vimeoIframeRef}
        src={
          isVimeoIframeInView
            ? `https://player.vimeo.com/video/` +
              props.vimeoId +
              `?muted=${props.muted}&autoplay=${props.autoplay}&loop=${props.loop}` +
              props.hParam
            : null
        }
        frameBorder="0"
        allow={props.allowFeatures}
        className={`${styles.vimeoVideoFrame} ${props.class}`}
        title={props.videoTitle || props.title}
        style={{ maxWidth: props.maxWidth }}
      />
      {props.showTitle && (
        <h5 className={`text-green-60 mt-1 ${props.classTitle}`}>{props.videoTitle || props.title}</h5>
      )}
    </>
  );
};
export default VimeoVideoFrame;
VimeoVideoFrame.defaultProps = {
  id: "video-iframe",
  vimeoId: "",
  videoTitle: "",
  title: "",
  class: "m-auto iframe h-100 w-100",
  classTitle: "",
  allowFeatures: "fullscreen", // "autoplay; fullscreen",
  muted: 0,
  autoplay: 0,
  loop: 0,
  hParam: "",
  maxWidth: ""
};
